
$(document).ready(function(){

  $(window).on("load resize",function(e){
    e.preventDefault();
      toggleMedia();
  });
  function toggleMedia() {
    let wdt = window.innerWidth;
    if (wdt >= 1200){
      $('#app-sidepanel').addClass('sidepanel-visible')
      $('#app-sidepanel').removeClass('sidepanel-hidden')
    }
    else{
      $('#app-sidepanel').removeClass('sidepanel-visible')
      $('#app-sidepanel').addClass('sidepanel-hidden')
    }
  }

  $(document).ready(function(){
    $('#sidepanel-toggler').on('click', function(e){
       e.preventDefault();
      if ($('#app-sidepanel').hasClass('sidepanel-hidden')){
        $('#app-sidepanel').removeClass('sidepanel-hidden')
        $('#app-sidepanel').addClass('sidepanel-visible')
      }
      else{
        $('#app-sidepanel').removeClass('sidepanel-visible');
        $('#app-sidepanel').addClass('sidepanel-hidden');
      }
    });
  });

  $('#sidepanel-close').click(function(e){
    e.preventDefault();
    $('#sidepanel-toggler').click();
  });

  $('#sidepanel-drop').click(function(e){
    e.preventDefault();
    $('#sidepanel-toggler').click();
  });

  // level_permissions form for slect role
 
});

